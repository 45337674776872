.side-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    margin: auto;
}
.side-nav__top{
    height: 60px;
    width: 90%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.theme-toggle{
  justify-self: flex-start;
}
.close-nav__btn{
   height: 30px;
   width: 40px;
   background: transparent;
   border: none;
   color: #E64A19;
   font-size: 20px;
}
.side-navbar{
    padding: 10px;
}
.side-navbar .nav-items{
    display: block;
    margin: 20px 2px;
}

@media(min-width:600px){
    .side-nav{
        display: none;
    }
     
}