.projects-page{
    padding-bottom: 80px;
}
.project-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: stretch;
    margin-top: 60px;
}

.projects{
    height: 300px;
    width: 450px;
    justify-self: center;
    margin: 15px;
    box-shadow: 2px 0px 19px -8px rgba(15,15,15,1);
    border-radius: 10px;
}
.bg-overlay{
    height: 300px;
    width: 450px;
    position: absolute;
    z-index: 2;
    background: rgb(46,45,45);
    background: linear-gradient(30deg, rgba(46,45,45,0.9626225490196079) 0%, rgba(6,6,6,0.5676645658263305) 100%);
    border-radius: 10px;
}
.bg-overlay:hover{
    opacity: 0.5;
}
.projects-img{
    height: 300px;
    width: 450px;
    position: absolute;
    border-radius: 10px;
}
.project-txt{
    position: absolute;
    z-index: 3;
    padding: 170px 40px 40px 40px;
    color: #fff;
}
.project-txt h3{
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 25px;
}

@media(max-width:900px){
    .project-wrapper{
        grid-template-columns: 1fr;
    }
}

@media(max-width:600px){
    .projects{
        width: 300px;
        height: 200px;
    }
    .bg-overlay{
        width: 300px;
        height: 200px;
    }
    .projects-img{
        width: 300px;
        height: 200px;
    }
    .project-txt{
        padding: 70px 20px 20px 10px;
    }
}
