/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap');

@font-face {
  font-family: 'Aline Signature';
  src: url('./assets/fonts/AlineSignature.eot');
  src: url('./assets/fonts/AlineSignature.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AlineSignature.woff2') format('woff2'),
      url('./assets/fonts/AlineSignature.woff') format('woff'),
      url('./assets/fonts/Aline\ Signature.ttf'),
      url('./assets/fonts/AlineSignature.svg#AlineSignature') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arialic Hollow';
  src: url('./assets/fonts/ArialicHollow.eot');
  src: url('./assets/fonts/ArialicHollow.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/ArialicHollow.woff2') format('woff2'),
      url('./assets/fonts/ArialicHollow.woff') format('woff'),
      url('./assets/fonts/Arialic\ Hollow.ttf'),
      url('./assets/fonts/ArialicHollow.svg#ArialicHollow') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.container{
  width: 90%;
  margin: auto;
}