.navbar{
    list-style: none;
    margin-top: 0;
}
.nav-items{
    display: inline;
    margin-right: 60px;
    
} 
.link{
    text-decoration: none;
    color: #E64A19;
}
.link:hover{
    color: #e649195d;
}

@media(max-width:600px){
    .navbar{
        display: none;
    }
}