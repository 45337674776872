@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggler-icon{
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.toggler-icon:hover{
    opacity: 0.5;
}
.navbar{
    list-style: none;
    margin-top: 0;
}
.nav-items{
    display: inline;
    margin-right: 60px;
    
} 
.link{
    text-decoration: none;
    color: #E64A19;
}
.link:hover{
    color: #e649195d;
}

@media(max-width:600px){
    .navbar{
        display: none;
    }
}
.side-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    margin: auto;
}
.side-nav__top{
    height: 60px;
    width: 90%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.theme-toggle{
  justify-self: flex-start;
}
.close-nav__btn{
   height: 30px;
   width: 40px;
   background: transparent;
   border: none;
   color: #E64A19;
   font-size: 20px;
}
.side-navbar{
    padding: 10px;
}
.side-navbar .nav-items{
    display: block;
    margin: 20px 2px;
}

@media(min-width:600px){
    .side-nav{
        display: none;
    }
     
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }
  

.main-nav{
    margin-top: 20px;
    width: 100%;
    height: 70px;
}
.brand{
    float: left; 
}
.brand-link{
    text-decoration: none;
    color: #E64A19;
    font-family: 'Aline Signature';
}
.brand-link:hover{
    color: #e649195d;
}
.nav-wrapper{
    float: right; 
}
.side-nav__btn{
    display: none;
}
.side-nav__btn{
    width: 60px;
    height: 40px;
    background: transparent;
    color: #E64A19;
    cursor: pointer;
    font-size: 22px;
    border: none;
    margin-top: -10px;
    padding: 0px;
}
/* .side-nav__btn span{
    display: block;
    width: 20px;
    height: 2px;
    background: #E64A19;
} */

.side-nav__drawer{
    display: none;
}
@media(max-width:600px){
    .side-nav__btn{
        display: inline;
    }
}

body{
    font-family: 'Nunito', sans-serif;
}

.hero-wrapper{
    height: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 40px;
}
.hero-txt{
    margin-top: 20px;
    padding-right: 50px;
}
.hero-txt h1{
    font-family: 'Arialic Hollow';
    font-weight: normal;
    font-style: normal;
    font-size: 70px;
    margin-bottom: 5px;
}
.hero-txt p{
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 15px;
}
.social-link i{
    font-size: 25px;
    margin-right:30px;
    color: #868686;
}
.social-link i:hover{
    color: #424242;
}
.hyper-link__wrapper{
    margin-top: 35px;
}
.hyper-link{
    display: inline;
    text-decoration: none;
    font-size: 35px; 
}

.hero-btn{
    margin-top: 60px;
    height: 47px;
    width: 300px;
    background-color: rgba(230, 73, 25, 0.356);
    color: #E64A19;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    text-decoration: none;
}
.hero-btn:hover{
    background-color: #E64A19;
    color: #fff;
}
.hero-btn i{
    margin-left: 20px;
}
/* Hero memoji section */
.hero-memoji{
   display: flex;
   justify-content: flex-end;
   padding: 120px;
}
.memoji-wrapper{
    background-color: #1C1B20;
    border-radius: 50%;
    padding: 20px;
    height: 200px;
    width: 200px;
}
.memoji-img{
    height: 200px;
    width: 200px;
}

@media(max-width:600px){
    .home{
        text-align: center;
        padding-bottom: 50px;
    }
    .hero-wrapper{
        grid-template-columns: 1fr;
    }
    .hero-txt{
        padding: 10px;
    }
    .hero-txt h1{
        font-size: 32px;
        font-weight: bolder;
    }
    .hero-txt br{
        display: none;
    }
    .hero-txt p{
        font-size: 13px;
    }
    .social-link{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 45px;
    }
    .social-link i{
        margin: auto;
        font-size: 18px;
    }
    .hero-memoji{
        padding: 0px;
        justify-content: center;
        grid-column-start: 1;
        grid-row-start: 1;
    }
    .memoji-wrapper{
        height: 130px;
        width: 130px;
    }
    .memoji-img{
        height: 130px;
        width: 130px;
    }
}
body{
    overflow-x: hidden;
}
.page-header{
    margin-top: 30px;
    font-size: 30px;
    margin-bottom: 0;
}
.page-header h3{
    margin-bottom: 2px;
}
.header-line{
    height: 2px;
    width: 90px;
    background-color: #E64A19;
    margin: 0;
}
.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top:50px;
    padding-bottom: 60px;
}
.about-txt{
    padding: 50px 120px 50px 0;
}
.about-img{
    overflow-x: hidden;
}
.bg-big__txt{
    position: absolute;
    top: 55px;
    margin-bottom: 80px;
    font-size: 300px;
    font-weight: bolder;
    color: #89898928;
    letter-spacing: 6px;
}
.memoji2-img{
    position: absolute;
    right: 448px;
    top: 220px;
    height:133px;
    width:130px;
}
.clint-img{
    height: 500px;
    margin-left: 150px;

}

@media(max-width:600px){
    body{
        overflow-x: hidden;
    }
    .wrapper{
        grid-template-columns: 1fr;
        overflow-x: hidden;
    }
    .about-txt{
        padding: 0;
    }
    .about-img{
      display: none;
    }
}
.projects-page{
    padding-bottom: 80px;
}
.project-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: stretch;
    margin-top: 60px;
}

.projects{
    height: 300px;
    width: 450px;
    justify-self: center;
    margin: 15px;
    box-shadow: 2px 0px 19px -8px rgba(15,15,15,1);
    border-radius: 10px;
}
.bg-overlay{
    height: 300px;
    width: 450px;
    position: absolute;
    z-index: 2;
    background: rgb(46,45,45);
    background: linear-gradient(30deg, rgba(46,45,45,0.9626225490196079) 0%, rgba(6,6,6,0.5676645658263305) 100%);
    border-radius: 10px;
}
.bg-overlay:hover{
    opacity: 0.5;
}
.projects-img{
    height: 300px;
    width: 450px;
    position: absolute;
    border-radius: 10px;
}
.project-txt{
    position: absolute;
    z-index: 3;
    padding: 170px 40px 40px 40px;
    color: #fff;
}
.project-txt h3{
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 25px;
}

@media(max-width:900px){
    .project-wrapper{
        grid-template-columns: 1fr;
    }
}

@media(max-width:600px){
    .projects{
        width: 300px;
        height: 200px;
    }
    .bg-overlay{
        width: 300px;
        height: 200px;
    }
    .projects-img{
        width: 300px;
        height: 200px;
    }
    .project-txt{
        padding: 70px 20px 20px 10px;
    }
}


/* Fonts */

@font-face {
  font-family: 'Aline Signature';
  src: url(/static/media/AlineSignature.ae049428.eot);
  src: url(/static/media/AlineSignature.ae049428.eot?#iefix) format('embedded-opentype'),
      url(/static/media/AlineSignature.17568245.woff2) format('woff2'),
      url(/static/media/AlineSignature.6275073f.woff) format('woff'),
      url("/static/media/Aline Signature.24cf0777.ttf"),
      url(/static/media/AlineSignature.e7c963d5.svg#AlineSignature) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arialic Hollow';
  src: url(/static/media/ArialicHollow.69d17aeb.eot);
  src: url(/static/media/ArialicHollow.69d17aeb.eot?#iefix) format('embedded-opentype'),
      url(/static/media/ArialicHollow.0333d91c.woff2) format('woff2'),
      url(/static/media/ArialicHollow.d325d61e.woff) format('woff'),
      url("/static/media/Arialic Hollow.57d6fd87.ttf"),
      url(/static/media/ArialicHollow.985642e2.svg#ArialicHollow) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.container{
  width: 90%;
  margin: auto;
}
