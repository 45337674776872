body{
    overflow-x: hidden;
}
.page-header{
    margin-top: 30px;
    font-size: 30px;
    margin-bottom: 0;
}
.page-header h3{
    margin-bottom: 2px;
}
.header-line{
    height: 2px;
    width: 90px;
    background-color: #E64A19;
    margin: 0;
}
.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top:50px;
    padding-bottom: 60px;
}
.about-txt{
    padding: 50px 120px 50px 0;
}
.about-img{
    overflow-x: hidden;
}
.bg-big__txt{
    position: absolute;
    top: 55px;
    margin-bottom: 80px;
    font-size: 300px;
    font-weight: bolder;
    color: #89898928;
    letter-spacing: 6px;
}
.memoji2-img{
    position: absolute;
    right: 448px;
    top: 220px;
    height:133px;
    width:130px;
}
.clint-img{
    height: 500px;
    margin-left: 150px;

}

@media(max-width:600px){
    body{
        overflow-x: hidden;
    }
    .wrapper{
        grid-template-columns: 1fr;
        overflow-x: hidden;
    }
    .about-txt{
        padding: 0;
    }
    .about-img{
      display: none;
    }
}