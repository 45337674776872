
body{
    font-family: 'Nunito', sans-serif;
}

.hero-wrapper{
    height: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 40px;
}
.hero-txt{
    margin-top: 20px;
    padding-right: 50px;
}
.hero-txt h1{
    font-family: 'Arialic Hollow';
    font-weight: normal;
    font-style: normal;
    font-size: 70px;
    margin-bottom: 5px;
}
.hero-txt p{
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 15px;
}
.social-link i{
    font-size: 25px;
    margin-right:30px;
    color: #868686;
}
.social-link i:hover{
    color: #424242;
}
.hyper-link__wrapper{
    margin-top: 35px;
}
.hyper-link{
    display: inline;
    text-decoration: none;
    font-size: 35px; 
}

.hero-btn{
    margin-top: 60px;
    height: 47px;
    width: 300px;
    background-color: rgba(230, 73, 25, 0.356);
    color: #E64A19;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    text-decoration: none;
}
.hero-btn:hover{
    background-color: #E64A19;
    color: #fff;
}
.hero-btn i{
    margin-left: 20px;
}
/* Hero memoji section */
.hero-memoji{
   display: flex;
   justify-content: flex-end;
   padding: 120px;
}
.memoji-wrapper{
    background-color: #1C1B20;
    border-radius: 50%;
    padding: 20px;
    height: 200px;
    width: 200px;
}
.memoji-img{
    height: 200px;
    width: 200px;
}

@media(max-width:600px){
    .home{
        text-align: center;
        padding-bottom: 50px;
    }
    .hero-wrapper{
        grid-template-columns: 1fr;
    }
    .hero-txt{
        padding: 10px;
    }
    .hero-txt h1{
        font-size: 32px;
        font-weight: bolder;
    }
    .hero-txt br{
        display: none;
    }
    .hero-txt p{
        font-size: 13px;
    }
    .social-link{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 45px;
    }
    .social-link i{
        margin: auto;
        font-size: 18px;
    }
    .hero-memoji{
        padding: 0px;
        justify-content: center;
        grid-column-start: 1;
        grid-row-start: 1;
    }
    .memoji-wrapper{
        height: 130px;
        width: 130px;
    }
    .memoji-img{
        height: 130px;
        width: 130px;
    }
}