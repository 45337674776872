
.main-nav{
    margin-top: 20px;
    width: 100%;
    height: 70px;
}
.brand{
    float: left; 
}
.brand-link{
    text-decoration: none;
    color: #E64A19;
    font-family: 'Aline Signature';
}
.brand-link:hover{
    color: #e649195d;
}
.nav-wrapper{
    float: right; 
}
.side-nav__btn{
    display: none;
}
.side-nav__btn{
    width: 60px;
    height: 40px;
    background: transparent;
    color: #E64A19;
    cursor: pointer;
    font-size: 22px;
    border: none;
    margin-top: -10px;
    padding: 0px;
}
/* .side-nav__btn span{
    display: block;
    width: 20px;
    height: 2px;
    background: #E64A19;
} */

.side-nav__drawer{
    display: none;
}
@media(max-width:600px){
    .side-nav__btn{
        display: inline;
    }
}